import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaIdCard } from 'react-icons/fa';
import './ContactUs.css';

function ContactUs() {
  return (
    <div className="contact-us">
      <h2>Get in Touch</h2>
      <p>We're here to provide you with more information, answer any questions you may have.</p>
      
      <div className="contact-item">
        <FaMapMarkerAlt className="contact-icon" />
        <div>
          <h4>Visit Us At</h4>
          <p>165 van der bijl street, driehoek, Germiston</p>
        </div>
      </div>
      
      <div className="contact-item">
        <FaPhoneAlt className="contact-icon" />
        <div>
          <h4>Call Vinolia</h4>
          <p>060 949 9113</p>
        </div>
      </div>
      
      <div className="contact-item">
        <FaIdCard className="contact-icon" />
        <div>
          <h4>Sahpra License Number</h4>
          <p>00002730MD</p>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
