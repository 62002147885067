import React from 'react';
import './AboutUs.css';
import { FaMedkit, FaHandsHelping, FaServicestack } from 'react-icons/fa';
import useInView from '../Services/useInView'; // Assume this is the same hook you showed before

function AboutUs() {
  const [ref, isInView] = useInView();
  
  return (
    <div className="about-us" ref={ref}>
      <div className={`content-section ${isInView ? "fade-in" : ""}`}>
        <FaMedkit className="section-icon"/>
        <h2>Products and Services</h2>
        <p>
          Remember Medical Distribution's product mix encompasses a plethora of diverse 
          products for all medical and surgical needs, including surgical consumables and 
          specialty products utilized daily.
        </p>
      </div>
      <div className={`content-section ${isInView ? "slide-in" : ""}`}>
        <FaHandsHelping className="section-icon"/>
        <h2>Service and Support</h2>
        <p>
          Esteemed for our exceptional customer service and relations, Remember Medical assures 
          you that we are your premier supplier. Our team, committed to catering to all your 
          needs, emphasizes genuine service and support. Every product boasts international 
          certifications to guarantee quality standards across all furniture, equipment, and 
          medical consumables. We strive to fulfill every customer need—if we don’t have it, 
          we'll source it!
        </p>
      </div>
      <div className={`content-section ${isInView ? "zoom-in" : ""}`}>
        <FaServicestack className="section-icon"/>
        <h2>Services</h2>
        <p>
          Remember Medical is a proudly South African company concentrating on the sales and 
          distribution of Medical Furniture and Hospital Equipment. Our foundation is laid 
          upon medical expertise, unparalleled client service, and the persistent pursuit of 
          medical and clinical innovation. The ethos of constant improvement and growth is 
          ingrained within the culture of Remember Medical, and the brand is solidly 
          established within the South African Medical supply chain.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
