import React from "react";
import {
  FaAmbulance,
  FaStethoscope,
  FaChair,
  FaProcedures,
  FaUserMd,
  FaTools,
  FaCouch,
  FaDolly,
  FaWheelchair,
  FaUserNurse,
  FaClinicMedical,
  FaBandAid,
} from "react-icons/fa";
import "./Services.css";
import ServiceCard from './ServiceCard';

import useInView from "./useInView";

function Services() {
  const [ref, isInView] = useInView();

  const services = [
    {
      name: "Emergency Equipment",
      icon: <FaAmbulance />,
      desc: "Essential tools for urgent care.",
    },
    {
      name: "Diagnostic Equipment",
      icon: <FaStethoscope />,
      desc: "Tools for precise health evaluation.",
    },
    {
      name: "Theatre Furniture",
      icon: <FaChair />,
      desc: "Quality furniture for medical theatres.",
    },
    {
      name: "Doctor's Rooms",
      icon: <FaUserMd />,
      desc: "Well-equipped spaces for physicians.",
    },
    {
      name: "Accessories",
      icon: <FaTools />,
      desc: "Assorted accessories for healthcare settings.",
    },
    {
      name: "Medical Furniture",
      icon: <FaProcedures />,
      desc: "Furniture designed for medical use.",
    },
    {
      name: "Material Handling",
      icon: <FaDolly />,
      desc: "Efficient solutions for material transport.",
    },
    {
      name: "Mobility & Frail Care Furniture",
      icon: <FaWheelchair />,
      desc: "Supportive furniture for enhanced mobility.",
    },
    {
      name: "Seating",
      icon: <FaCouch />,
      desc: "Comfortable seating for varied needs.",
    },
    {
      name: "Nursing Areas",
      icon: <FaUserNurse />,
      desc: "Dedicated areas for proficient nursing.",
    },
    {
      name: "First Aid, Day Clinic & Sick Rooms",
      icon: <FaClinicMedical />,
      desc: "Ready-to-use first aid and clinic rooms.",
    },
    {
      name: "Consumables",
      icon: <FaBandAid />,
      desc: "Essential consumables for medical procedures.",
    },
  ];
  return (
    <div className="services-section">
      <h2>Our Specialized Services</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <ServiceCard service={service} key={index} />
        ))}
      </div>
    </div>
  );
}

export default Services;
