import React from 'react'
import './HeroSection.css'
import { Link } from "react-scroll";

function HeroSection() {
  return (
   <section className='hero'>
    <div className='hero-content'>
        <div className='hero-header'>Welcome to MoreMed</div>
        <div className='hero-subheader'>Excelence in Medical Distribution and Services</div>
        <Link
          activeClass="active"
          to="services"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
        <button className='cta-button'> Explore Our Services</button>
        </Link>
    </div>
   </section>
  )
}

export default HeroSection