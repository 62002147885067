// ServiceCard.js
import React from 'react';
import useInView from './useInView';

function ServiceCard({ service }) {
    const [ref, isInView] = useInView();

    return (
        <div 
            ref={ref} 
            className={`service-card ${isInView ? 'in-view' : ''}`}
        >
            <div className={`service-icon ${isInView ? 'in-view' : ''}`}>{service.icon}</div>
            <h3 className={`cards_headers ${isInView ? 'in-view' : ''}`}>{service.name}</h3>
            <p className={`cards_para ${isInView ? 'in-view' : ''}`}>{service.desc}</p>
        </div>
    );
}

export default ServiceCard;
