import React from "react";
import Navbar from "../../Navbar/Navbar";
import HeroSection from "../../HeroSection/HeroSection";
import Services from "../../Services/Services";
import AboutUs from "../../AboutUs/AboutUs";
import ContactUs from "../../ContactUs/ContactUs";
import Footer from "../../Footer/Footer";

function Home() {
  return (
    <div>
      <Navbar/>
      <HeroSection />
      <div id="aboutUs">
        <AboutUs/>
      </div>
      <div id="services">
        <Services/>
      </div>
      <div id="contactUs">
        <ContactUs/>
      </div>
      <Footer/>
    </div>
  );
}

export default Home;
