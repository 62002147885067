import React, { useState } from "react";
import { FaMedkit } from "react-icons/fa";
import { Link } from "react-scroll";
import "./Navbar.css";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick = () => {
    setIsOpen(false); // Close the mobile menu when a link is clicked
  };

  return (
    <nav className="navbar">
      <div className="nav-logo">
        <FaMedkit size={30} style={{ marginRight: "15px" }} />
        MoreMed Distribution
      </div>
      <ul className={`nav-links ${isOpen ? "open" : ""}`}>
        <Link
          activeClass="active"
          to="aboutUs"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <li onClick={handleLinkClick}>About</li>
        </Link>
        <Link
          activeClass="active"
          to="services"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <li onClick={handleLinkClick}>Services</li>
        </Link>
        <Link
          activeClass="active"
          to="contactUs"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <li onClick={handleLinkClick}>Contact</li>
        </Link>
      </ul>

      <div className="cta-button-nav">
        <Link
          to="contactUs"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          Get in Touch
        </Link>
      </div>

      <div className="hamburger" onClick={() => setIsOpen(!isOpen)}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </nav>
  );
}

export default Navbar;
