import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import './Footer.css';

function Footer() {
    return (
        <div className="footer">
            <div className="footer-content">
                <div className="footer-section about">
                    <h1 className="logo-text"><span>More</span>Med</h1>
                    <p>
                        MoreMed is committed to providing precision and quality 
                        in medical distribution. Our mission is to ensure reliability 
                        and satisfaction through our range of products and services.
                    </p>
                </div>
                
                <div className="footer-section contact-form">
                    <h2>Contact Us</h2>
                    <div className="contact">
                        <span><FaPhoneAlt /> &nbsp; 060 949 9113</span>
                        <span><FaEnvelope /> &nbsp; info@moremed.co.za</span>
                        <span><FaMapMarkerAlt /> &nbsp; 165 van der bijl street, driehoek, Germiston</span>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <p>&copy; 2023 MoreMed | Designed with care</p>
            </div>
        </div>
    );
}

export default Footer;
