import { useState, useEffect, useRef } from 'react';

const useInView = () => {
    const [isInView, setIsInView] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIsInView(entry.isIntersecting),
            {
                rootMargin: '-35% 0px -15% 0px', // Adjusts the margin to trigger when the item is halfway on the screen.
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref]);

    return [ref, isInView];
};

export default useInView;
